import template from './product-look-up.html';

(function ($, ko) {
    function ProductLookUp(params) {
        const self = this;
        self.storeProducts = ko.observableArray();
        self.productIds = ko.observableArray();
        self.productKeyword = ko.observable('')
        self.productSearchName = ko.observable('');
        self.productLookUpService = new $.cdh.http.smartAccount.ProductLookUpService;
        self.disableSearchButton = ko.observable(false);
        self.disableScanner = ko.observable(false);
        self.disableScannerButton = ko.observable(false);
        self.html5QrCode = new Html5Qrcode("reader");
        document.getElementById("reader").style.display = "none";
        document.getElementById("product-search-results").style.display = "block";
        self.showBarcodeScanError = ko.observable(false);
        self.isScanning = false;
        self.showPermissionDeniedError = ko.observable(false);
        self.starLoaderForScanner = ko.observable(false);

        // code to check if the application is accessed via mobile browser
        self.isMobileBrowser = function () {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            // Check for iOS or Android
            return /iPhone|iPad|iPod|Android/i.test(userAgent);
        }
        self.disableScanner(self.isMobileBrowser());
        self.searchProducts = function (data, event) {
            document.getElementById("product-search-results").style.display = "block";
            self.disableScannerButton(false);
            self.showBarcodeScanError(false);
            self.showPermissionDeniedError(false);
            if (self.isMobileBrowser()) {
                self.closeScanner();
                self.isScanning = false;
                const readerDiv = document.getElementById("reader");
                readerDiv.style.display = "none";
                const searchResultsDiv = document.querySelector(".product-search-results");
                const searchBarDiv = document.querySelector(".product-search-bar");
                searchBarDiv.parentNode.insertBefore(searchResultsDiv, searchBarDiv.nextSibling);
            }
            self.productIds([]);
            self.productKeyword('');

            var searchProduct = self.productSearchName().trim();
            var isOnlyDigits = /^[\d\s,]+$/.test(searchProduct);
            var isOnlyAlphabets = /^[a-zA-Z\s,]+$/.test(searchProduct);

            if (isOnlyDigits) {
                if (self.productSearchName().includes(',')) {
                    self.productIds(self.productSearchName().split(',').map(item => item.trim()).filter(value => value.length));
                } else {
                    self.productIds.push(searchProduct)
                }
            }
            else if (isOnlyAlphabets) {
                if (self.productSearchName().includes(',')) {
                    const firstProductName = self.productSearchName().split(',')[0].trim();
                    self.productKeyword(firstProductName);
                } else {
                    self.productKeyword(searchProduct)
                }
            }

            const payload = {
                "ProductIds": self.productIds(),
                "productsKeyword": self.productKeyword()
            }

            self.productLookUpService.ProductSearch(payload).then(function (result) {
                self.storeProducts(result.xhr.responseJSON.Products);
            }).catch(function (data) {
                self.storeProducts([]);
            });
        };

        self.getMobileOperatingSystem = function () {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/iPhone|iPad|iPod/i.test(userAgent)) {
                return "iOS";
            }
            if (/Android/i.test(userAgent)) {
                return "Android";
            }
            return "Other";
        }

        // barcode scanner functionality
        self.openScanner = function (data, event) {
            if (!self.isScanning) {
                self.starLoaderForScanner(true);
                self.showBarcodeScanError(false);
                self.showPermissionDeniedError(false);
                document.getElementById("reader").style.display = "flex";
                document.getElementById("reader").style.opacity = "0";
                document.getElementById("product-search-results").style.display = "none";

                const onScanSuccess = (decodedText, decodedResult) => {
                    self.showBarcodeScanError(false);
                    document.getElementById("reader").style.display = "none";
                    self.disableScannerButton(false);
                    self.productSearchName(decodedText);
                    self.disableSearchButton(true);
                    self.searchProducts();
                    self.html5QrCode.stop().then(() => {
                        self.isScanning = false;
                    });
                };
                if (!self.isScanning) {
                    self.html5QrCode.start(
                        {},
                        {
                            fps: (self.getMobileOperatingSystem() == "iOS") ? 20 : 10,
                            qrbox: { width: 300, height: 250 },
                            videoConstraints: {
                                facingMode: "environment",
                                focusMode: "continuous"
                            },
                        },
                        onScanSuccess,
                        (errorMessage) => {
                            self.showBarcodeScanError(false);
                            const error = errorMessage.includes("No MultiFormat Readers were able to detect the code") ||
                                errorMessage.includes("No barcode or QR code detected") ||
                                errorMessage.includes("scanner is not running or paused.");

                            if (!error) {
                                self.starLoaderForScanner(false);
                                self.showBarcodeScanError(true);
                            }
                        }
                    ).then(() => {
                        self.isScanning = true;
                    }).catch(err => {
                        if ((err.indexOf('Permission denied') > -1) || (err.indexOf('user denied permission') > -1)) {
                            self.showBarcodeScanError(false);
                            self.starLoaderForScanner(false);
                            self.showPermissionDeniedError(true);
                        }
                    });
                }

            // Use MutationObserver to apply styles once the video element is added
            const observer = new MutationObserver(() => {
                const interval = setInterval(() => {
                    const videoElement = $("#reader video")[0];
                    const qrShadeRegion = document.querySelector("#reader #qr-shaded-region");
                    const closeButton = document.querySelector("#closeButton");
                    if (qrShadeRegion && videoElement) {
                        clearInterval(interval);
                        if (self.getMobileOperatingSystem() == "iOS") {
                            videoElement.classList.add("applyVideoStylesIos");
                            closeButton.classList.add("applyCloseBtnStylesIos");
                        } else {
                            videoElement.classList.add("applyVideoStylesAndroid");
                            qrShadeRegion.classList.add("applyQRStylesAndroid");
                            closeButton.classList.add("applyCloseBtnStylesAndroid");
                        }
                        self.starLoaderForScanner(false);
                        document.getElementById("reader").style.opacity = "100";
                        self.disableScannerButton(true);
                    }
                }, 100);
                observer.disconnect();
            });
            observer.observe(document.getElementById("reader"), { childList: true, subtree: true });
            }
        }

        self.closeScanner = function () {
            self.showBarcodeScanError(false);
            self.showPermissionDeniedError(false);
            document.getElementById("reader").style.display = "none";
            document.getElementById("product-search-results").style.display = "block";
            self.disableScannerButton(false);
            // Stop the scanner
            if (self.isScanning) {
                self.html5QrCode.stop().then(() => {
                    self.isScanning = false;
                }).catch(err => { });
            }
        }

        // Function to trigger on Enter key press
        self.searchOnEnter = function (data, event) {
            if (event.key === 'Enter' && self.disableSearchButton()) {
                self.searchProducts();
                return false;
            }
            return true;
        };


        document.getElementById('ProductId').addEventListener('keyup', function (newData) {
            if (newData.target.value.length > 0) {
                self.productSearchName(newData.target.value);
                self.disableSearchButton(true)
            } else {
                self.disableSearchButton(false)
            }
        })
    }

    self.onlyAlpaNumericAndComma = function (data, event) {
        const charCode = event.charCode;
        const char = String.fromCharCode(charCode);
        const isValid = /^[A-Za-z0-9, ]$/.test(char);
        return isValid;
    };

    ko.components.register('product-look-up', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new ProductLookUp(params, componentInfo);
            }
        }
    });

    self.clickFindStores = function () {
        window.location.href = $.cdh.routes.predefined.ila.merchants
    };

})(jQuery, ko);